<template>
	<v-container id="permission" fluid tag="section">
		<v-card>
			<v-card-title>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Búsqueda de Roles"
					single-line
					hide-details
				></v-text-field>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="desserts"
				:search="search"
				sort-by="role"
				class="elevation-1"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>Administración de Roles</v-toolbar-title>
						<v-divider class="mx-4" inset vertical></v-divider>
						<v-spacer></v-spacer>

						<v-dialog v-model="dialog" max-width="90%" persistent>
							<template v-if="visible" v-slot:activator="{ on, attrs }">
								<v-btn
									:color="$cv('principal')"
									dark
									class="mx-2"
									fab
									v-bind="attrs"
									v-on="on"
									><v-icon blue>mdi-plus</v-icon></v-btn
								>
							</template>
							<v-card>
								<v-form ref="form" v-model="valid" lazy-validation>
									<v-toolbar dark :color="$cv('principal')">
										<v-btn icon dark @click="dialog = false">
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
										<v-spacer></v-spacer>
										<v-toolbar-items>
											<v-btn dark text @click="save" :disabled="!valid"
												>Guardar</v-btn
											>
										</v-toolbar-items>
									</v-toolbar>

									<v-card-text>
										<v-container>
											<v-row>
												<v-col cols="12" md="12" sm="12">
													<v-text-field
														v-model="editedItem.name"
														label="Rol"
														:rules="rulesRequired"
													></v-text-field>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12" md="12" sm="12">
													<v-autocomplete
														:items="permissions"
														v-model="editedItem.permissions"
														item-value="id"
														item-text="name"
														chips
														label="Permisos"
														multiple
														outlined
														return-object
													>
													</v-autocomplete>
												</v-col>
											</v-row>
										</v-container>
									</v-card-text>
								</v-form>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</template>
				<template v-slot:[`item.created_at`]="{ item }">
					<span>{{
						item.created_at ? new Date(item.created_at).toLocaleString() : ""
					}}</span>
				</template>
				<template v-slot:[`item.updated_at`]="{ item }">
					<span>{{
						item.updated_at ? new Date(item.updated_at).toLocaleString() : ""
					}}</span>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon
						v-if="visible"
						small
						class="mr-2"
						:color="$cv('btnEditar')"
						title="Editar"
						@click="editItem(item.id)"
					>
						mdi-pencil
					</v-icon>
					<v-icon
						v-if="visible"
						small
						:color="$cv('btnEliminar')"
						title="Eliminar"
						@click="deleteItem(item.id)"
					>
						mdi-delete
					</v-icon>
				</template>
			</v-data-table>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
	</v-container>
</template>

<script>
	export default {
		data: (vm) => ({
			options: {
				locale: "es-AR",
				prefix: "$",
				suffix: "",
				length: 11,
				precision: 0,
			},
			attrs: false,
			on: false,
			valid: true,
			nowDate: new Date().toISOString().slice(0, 10),
			_method: "PUT",
			autoGrow: true,
			rows: 1,
			baseUrl: "/api/roles",
			menu: false,
			modal: false,
			menu2: false,
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
			search: "",
			rulesRequired: [(v) => !!v || "Requerido"],
			headers: [
				{ text: "Rol", filterable: true, value: "name" },
				{ text: "Permisos", filterable: true, value: "permisos_asignados" },
				{ text: "Creado", value: "created_at" },
				{ text: "Actualizado", value: "updated_at" },
				{ text: "Accciones", value: "actions", sortable: false },
			],

			desserts: [],
			editedIndex: -1,
			editedItem: {
				name: "",
				permissions: [],
				monto: "",
			},
			permissions: [],
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1 ? "Registrar Rol" : "Editar Rol";
			},
		},

		watch: {
			dialog(val) {
				val || this.close();
			},
		},

		created() {
			this.initialize();
			this.getPermissions();
		},

		methods: {
			getPermissions() {
				axios
					.get("api/permissions")
					.then((response) => {
						this.permissions = response.data;
					})
					.catch((response) => {
						this.snackbar = true;
						this.text = "Error obtener permissions. Error: " + response.message;
						this.color = "error";
					});
			},
			initialize() {
				axios
					.get(this.baseUrl)
					.then((r) => {
						this.desserts = r.data;
					})
					.catch(function (error) {
						this.snackbar = true;
						this.text = "Error al obtener rol. Error: " + error;
						this.color = "error";
					});
			},
			editItem(id) {
				axios
					.get(this.baseUrl + "/" + id)
					.then((r) => {
						this.editedItem = Object.assign({}, r.data);
						this.dialog = true;
						this.color = "success";
						this.editedIndex = r.data.id;
					})
					.catch(function (error) {
						this.snackbar = true;
						this.text = "Error al obtener datos del rol. Error: " + error;
						this.color = "error";
					});
			},
			deleteItem(id) {
				this.$confirm({
					message: `Eliminar Registro?`,
					button: {
						no: "Cancelar",
						yes: "Confirmar",
					},

					callback: (confirm) => {
						if (confirm) {
							axios.delete(this.baseUrl + "/" + id).then(
								(response) => {
									this.snackbar = true;
									this.text = "Registro Eliminado";
									this.initialize();
								},
								(error) => {
									this.snackbar = true;
									this.text = "Error al eliminar la planilla Error: " + e.message;
									this.color = "error";
								}
							);
						}
					},
				});
			},
			close() {
				this.dialog = false;
				this.resetValidation();
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
			},
			resetValidation() {
				this.$refs.form.resetValidation();
			},
			save() {
				if (this.$refs.form.validate()) {
					if (this.editedIndex > -1) {
						let formData = new FormData();
						let url = this.baseUrl + "/" + this.editedIndex;
						formData.append("_method", "PUT");

						axios
							.post(url, {
								_method: "PUT",
								data: this.editedItem,
							})
							.then((r) => {
								this.snackbar = true;
								this.text = "Registro Actualizado";

								this.initialize();
							})
							.catch((e) => {
								this.snackbar = true;
								this.text = "Error al actualizar el rol. Error: " + e.message;
								this.color = "error";
							});
					} else {
						let method = "post";
						let url = this.baseUrl;

						axios({
							method,
							url,
							data: this.editedItem,
						})
							.then((r) => {
								this.snackbar = true;
								this.initialize();
							})
							.catch((e) => {
								console.log(e);
								this.snackbar = true;
								this.text = "Error al ingresar un rol. Error: " + e.message;
								this.color = "error";
							});
					}

					this.close();
				}
			},
		},

		mounted() {
			console.log("Componente Rol creado");
		},
	};
</script>
